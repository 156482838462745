import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { IOrder } from 'types/order';
import { fadeOut } from 'styles/helper';
import AppContext from 'AppContext';
import OrderCard from './Card';

interface IProps {
    order: IOrder;
}

const Wrapper = styled.div`
    overflow: hidden;
    animation: ${fadeOut} 400ms linear both;
`;

function StateTransitionCard({ order }: IProps) {
    const { onUpdateEnd } = useContext(AppContext.Context);

    useEffect(() => {
        return () => onUpdateEnd(order.id);
    }, [onUpdateEnd, order.id]);

    const onAnimationEnd = () => {
        onUpdateEnd(order.id);
    };
    return (
        <Wrapper onAnimationEnd={onAnimationEnd}>
            <OrderCard hideAction key={order.id} order={order} />
        </Wrapper>
    );
}

export default StateTransitionCard;

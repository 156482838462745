import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client';
//import { loader } from 'graphql.macro';
import { ApolloLink, from, Operation } from '@apollo/client';
import DebounceLink from 'apollo-link-debounce';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';

import { LocalStorageKey, getItem } from 'services/localStorageService';
import errorHandler from './errors';


//const getNetworkStatus = loader('../../queries/getNetworkStatus.graphql');

const DEFAULT_DEBOUNCE_TIMEOUT = 200;

const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

const retryLink = new RetryLink({
    delay: {
        initial: 15000,
        max: Infinity,
        jitter: false,
    },
    attempts: {
        max: Infinity,
        retryIf: (error, _operation) => {
            return (_operation.operationName === 'listOrders' || _operation.operationName === 'listOffers') && !!error;
        },
    },
});

const httpLink = createHttpLink({
    uri: window.config.API_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getItem<string>(LocalStorageKey.ACCESS_TOKEN);

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const omitTypenameLink = new ApolloLink((operation: Operation, forward: any) => {
    if (operation.variables) {
        const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation);
});

const cache = new InMemoryCache();
/*
cache.writeData({
    data: {
        isOffline: false,
    },
});
 */

export const client = new ApolloClient({
    link: from([
        new ApolloLink((operation, forward) => {
            // todo - writ result of network status query in cache
            /*client.readQuery({
                query: getNetworkStatus,
            });*/
            /*const networkStatus = client.readQuery({ query: getNetworkStatus });
            if (networkStatus.isOffline) {
                client.writeData({ data: { isOffline: false } });
            }*/
            return forward!(operation);
        }),
        debounceLink,
        retryLink,
        onError(error => {
            errorHandler.onError(error, client);
        }),
        omitTypenameLink,
        authLink,
        httpLink,
    ]),
    cache,
});

import React, { Component } from 'react';
import Auth from 'services/auth0';
import withRouter from 'utils/withRouter';

class CallbackComponent extends Component<{ router: any }> {
    async componentDidMount() {
        await Auth.handleAuthentication();

        return this.props.router.navigate('/');
    }

    render() {
        return <p>Loading permissions...</p>;
    }
}

export default withRouter(CallbackComponent);

import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LoaderImg } from 'ressources/img/loader.svg';

const Wrapper = styled.span`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface IProps {
    className?: string;
}

const Loader = ({ className }: IProps) => (
    <Wrapper className={className}>
        <LoaderImg height={200} />
    </Wrapper>
);

export default Loader;

import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

import { withTranslation, WithTranslation } from 'react-i18next';

import config from 'config.json';
import { ReactComponent as DeleteIcon } from 'ressources/img/delete.svg';

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-6px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(6px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-20px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(20px, 0, 0);
  }
`;

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.palette.background.default};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const KeyBoard = styled.div`
    display: grid;
    grid-template-columns: repeat(3);
    grid-template-rows: repeat(4);
    grid-gap: ${({ theme }) => theme.spacing.margin}px;
`;

const KeyBoardItem = styled.button`
    height: 107px;
    width: 107px;
    &:first-child {
        grid-column: 2;
        grid-row: 4;
    }
    border: 0px solid transparent;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.common.white};
    outline: none;
    font-size: ${({ theme }) => theme.typography.fontSizeXXL}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightExtraLight};
    color: ${({ theme }) => theme.palette.text.medium};
    &:active {
        opacity: 0.3;
    }
`;

// @ts-ignore
const Delete = styled(KeyBoardItem)`
    grid-column: 3;
    grid-row: 4;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h3`
    margin-bottom: ${({ theme }) => theme.spacing.margin * 3}px;
    color: ${({ theme }) => theme.palette.text.light};
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
`;

const Dislay = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing.margin * 3}px;
    &.shake {
        animation: ${shake} 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
`;

interface InputIndicarProps {
    readonly isActive: boolean;
}

const InputIndicar = styled.span<InputIndicarProps>`
    width: 40px;
    height: 40px;
    margin: 0 6px;
    background-color: ${({
        isActive,
        theme: {
            palette: {
                background: { darkOpacity, lightOpacity },
            },
        },
    }) => (isActive ? darkOpacity : lightOpacity)};
    border-radius: 50%;
    transition: background-color 150ms;
`;

interface IProps {
    toggleModal: () => void;
}

const initialState = { typedPINCode: '', shouldShake: false };

type IState = Readonly<typeof initialState>;

class PINModal extends Component<IProps & WithTranslation, IState> {
    state = initialState;

    type = (number: string) => {
        const { toggleModal } = this.props;
        this.setState(state => {
            const updatedTypedPINCode = state.typedPINCode + number;
            if (updatedTypedPINCode.length > config.SECRET_PIN_CODE.length) return null;
            // typed correct code
            if (updatedTypedPINCode === config.SECRET_PIN_CODE) {
                toggleModal();
                return { ...state, typedPINCode: '' };
            }
            // typed wrong password
            if (updatedTypedPINCode.length === config.SECRET_PIN_CODE.length) {
                return { typedPINCode: updatedTypedPINCode, shouldShake: true };
            }
            return { ...state, typedPINCode: updatedTypedPINCode };
        });
    };

    delete = () => {
        this.setState(({ typedPINCode }) => {
            if (typedPINCode.length === 0) return null;
            return { typedPINCode: typedPINCode.slice(0, typedPINCode.length - 1) };
        });
    };

    cleanUp = () => {
        this.setState({ typedPINCode: '', shouldShake: false });
    };

    render() {
        const { typedPINCode, shouldShake } = this.state;
        const { t } = this.props;
        return (
            <Wrapper>
                <Title>{t('component:PINModal.title')}</Title>
                <Dislay onAnimationEnd={this.cleanUp} className={shouldShake ? 'shake' : ''}>
                    {[...Array(4)].map((_, index: number) => (
                        <InputIndicar key={index} isActive={typedPINCode[index] !== undefined} />
                    ))}
                </Dislay>
                <KeyBoard>
                    {[...Array(10)].map((_, index: number) => (
                        <KeyBoardItem onTouchStart={() => this.type(index.toString())} key={index}>
                            {index}
                        </KeyBoardItem>
                    ))}
                    <Delete onTouchStart={this.delete}>
                        <DeleteIcon />
                    </Delete>
                </KeyBoard>
            </Wrapper>
        );
    }
}

export default withTranslation()(PINModal);

import React, { useState, AnimationEvent, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { IOrder, OrderState } from 'types/order';
import { ReactComponent as CheckImg } from 'ressources/img/check.svg';
import { fadeOut } from 'styles/helper';
import AppContext from 'AppContext';
import { withStateMutation, stateMutationOptimisticResponse } from '../../queries/mutations';

const load = keyframes`
  from { width: 0%; }
  to { width: 100%; }
`;

const Wrapper = styled.div`
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({ theme }) => theme.width.orderCard}px;
    height: ${({ theme }) => theme.height.cardPlaceholder}px;
    margin-top: ${({ theme }) => theme.spacing.margin * 2}px;
    margin-right: ${({ theme }) => theme.spacing.margin}px;
    border: solid 2px white;
    border-radius: 6px;
    &.filled {
        animation: ${fadeOut} 400ms linear 200ms both;
    }
`;

const StyledCheckIcon = styled(CheckImg)`
    height: 90px;
    width: 110px;
    margin-top: ${({ theme }) => theme.height.cardHeader}px;
    transition: margin-top 400ms cubic-bezier(0.4, 0, 0.2, 1);
    .filled & {
        margin-top: ${({ theme }) => (theme.height.cardPlaceholder - theme.height.checkIcon) / 2}px;
    }
`;

const Button = styled.button`
    width: 260px;
    height: 65px;
    margin-top: ${({ theme }) => theme.spacing.margin * 2}px;
    background-color: ${({ theme }) => theme.palette.common.red};
    border-width: 0;
    outline: none;
    border-radius: 35px;
    color: ${({ theme }) => theme.palette.text.light};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    text-transform: uppercase;
    .filled & {
        display: none;
    }
`;

const ProgressBar = styled.div`
    width: 265px;
    margin-top: ${({ theme }) => theme.spacing.margin}px;
    background-color: ${({ theme }) => theme.palette.grey[1]};
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    .filled & {
        display: none;
    }
`;

const Fill = styled.span`
    display: block;
    height: 13px;
    background-color: ${({ theme }) => theme.palette.common.red};
    border-radius: 5px;
    opacity: 0.6;
    animation-name: ${load};
    animation-duration: 5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
`;

interface IProps {
    updateOrderState: Function;
    order: IOrder;
}

function CancellableStateTransitionCard({
    t,
    order,
    order: { id, paymentMethod },
    updateOrderState,
}: IProps & WithTranslation) {
    const [isBarFilled, setBarFilled] = useState(false);
    const { onCancellableUpdateEnd, searchDisplay } = useContext(AppContext.Context);

    const onAnimationEnd = () => {
        onCancellableUpdateEnd({ orderId: id });
    };

    const onBarFilled = (e: AnimationEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        if (searchDisplay) {
            onAnimationEnd();
        } else {
            setBarFilled(true);
        }
    };

    const onCancel = async () => {
        onCancellableUpdateEnd({ orderId: id, cancelEvent: true });
        try {
            await updateOrderState({
                variables: { idOrder: id, newState: OrderState.READY, paymentMethod },
                optimisticResponse: stateMutationOptimisticResponse(order, OrderState.READY),
            });
        } catch (error) {
            console.log(error);
            onCancellableUpdateEnd({ orderId: id });
        }
    };

    return (
        <Wrapper onAnimationEnd={onAnimationEnd} className={isBarFilled ? 'filled' : ''}>
            <StyledCheckIcon height="110px" />
            <Button onClick={onCancel}>{t(`app:button.cancel`)}</Button>
            <ProgressBar>
                <Fill onAnimationEnd={onBarFilled} />
            </ProgressBar>
        </Wrapper>
    );
}

export default withTranslation()(withStateMutation(CancellableStateTransitionCard));

import get from 'lodash/get';

import React from 'react';
import styled from 'styled-components';
import { Buffer } from "buffer";

import { ReactComponent as CheckIcon } from 'ressources/img/check.svg';
import { ReactComponent as HandIcon } from 'ressources/img/hand.svg';
import { ReactComponent as PrintIcon } from 'ressources/img/print.svg';
import { ReactComponent as TrashIcon } from 'ressources/img/trash.svg';
import { ReactComponent as FlameIcon } from 'ressources/img/flame.svg';
import { AppOrderState, IOrder, OrderState, LocalOrderState } from 'types/order';
import {
    listOrders_orders,
    listOrders_orders_edges_node,
    listOrders_orders_edges_node_orderItems,
    listOrders_orders_edges_node_orderItemsFormula
} from 'types/graphql/listOrders';
import { TabStatusType, TabKey } from 'types/tab';
import { ordersStatesCounters_ordersStatesCounters } from '../types/graphql/ordersStatesCounters';

export const selectOptions = [OrderState.ACCEPTED, OrderState.PREPARING, OrderState.READY, OrderState.COMPLETED];

type NextStateType = OrderState | null;
type PreviousStateType = OrderState | null;
type ModalButtonStateType = OrderState[] | null;
type SelectOptionStateType = OrderState[] | null;
export const orderStateUpdates: {
    [key in OrderState]: [NextStateType, ModalButtonStateType, SelectOptionStateType, PreviousStateType];
} = {
    [OrderState.ACCEPTED]: [OrderState.PREPARING, [OrderState.REFUSED, OrderState.PREPARING], selectOptions, null],
    [OrderState.PREPARING]: [OrderState.READY, [OrderState.REFUSED, OrderState.READY], selectOptions, null],
    [OrderState.READY]: [
        OrderState.COMPLETED,
        [OrderState.REFUSED, OrderState.COMPLETED],
        selectOptions,
        OrderState.PREPARING,
    ],
    [OrderState.COMPLETED]: [null, null, selectOptions, OrderState.READY],
    [OrderState.REFUSED]: [null, null, null, null],
    [OrderState.CANCELLED]: [null, null, null, null],
    [OrderState.ABANDONED]: [null, null, null, null],
};

export const actionIcons: { [key: string]: JSX.Element } = {
    [OrderState.READY]: <CheckIcon />,
    [OrderState.PREPARING]: <PrintIcon />,
    [OrderState.COMPLETED]: <HandIcon />,
    [OrderState.REFUSED]: <TrashIcon />,
};

const GreenCheckIcon = styled(CheckIcon)`
    path {
        fill: ${({ theme }) => theme.palette.text.order.COMPLETED};
    }
`;

export const stateIcons: { [key: string]: JSX.Element } = {
    [OrderState.COMPLETED]: <GreenCheckIcon />,
};

export const hasCardStateInfo: { [key: string]: boolean } = {
    [OrderState.ACCEPTED]: false,
    [OrderState.PREPARING]: false,
    [OrderState.READY]: false,
    [OrderState.COMPLETED]: true,
    [OrderState.REFUSED]: true,
    [OrderState.CANCELLED]: true,
    [OrderState.ABANDONED]: true,
};

export const labelIcons: { [key: string]: JSX.Element } = {
    [LocalOrderState.URGENT]: <FlameIcon />,
};

function getMinutesLeft(date: Date): number {
    const diff = date.getTime() - new Date().getTime();
    return Math.round(diff / 60000);
}

export const tabStates: TabStatusType = {
    [TabKey.PREPARE]: [OrderState.ACCEPTED, OrderState.PREPARING],
    [TabKey.DISTRIBUTE]: [OrderState.READY],
    [TabKey.DISTRIBUTED]: [OrderState.COMPLETED],
    [TabKey.CANCELLED]: [OrderState.CANCELLED, OrderState.REFUSED, OrderState.ABANDONED],
};

export const tabCounterKey: { [key in TabKey]: string } = {
    [TabKey.PREPARE]: 'tabPreparing',
    [TabKey.DISTRIBUTE]: 'tabReady',
    [TabKey.DISTRIBUTED]: 'tabCompleted',
    [TabKey.CANCELLED]: 'tabCanceled',
};

function sortOrdersToDistribute(orders: IOrder[]): IOrder[] {
    return orders.sort((order1: IOrder, order2: IOrder) => {
        const order1Time = order1.withdrawRange[0].getTime();
        const order2Time = order2.withdrawRange[0].getTime();
        if ((!order1.isLate && !order2.isLate) || (order1.isLate && order2.isLate)) {
            return order1Time > order2Time ? 1 : -1;
        }
        return order1.isLate && !order2.isLate ? 1 : -1;
    });
}

export function getOrderTabLists(orders: IOrder[], ordersList): [IOrder[], IOrder[], IOrder[], IOrder[]] {

    const ordersToPrepare = ordersList.PREPARE.filter((order) => tabStates[TabKey.PREPARE].includes(order.state));
    const ordersToDistribute = ordersList.DISTRIBUTE.filter((order) => tabStates[TabKey.DISTRIBUTE].includes(order.state));
    const ordersDistributed = ordersList.DISTRIBUTED.filter((order) => tabStates[TabKey.DISTRIBUTED].includes(order.state));
    const cancelledOrders = ordersList.CANCELLED.filter((order) => tabStates[TabKey.CANCELLED].includes(order.state));

    return [ordersToPrepare, sortOrdersToDistribute(ordersToDistribute), ordersDistributed, cancelledOrders];
}

export function getOrderStates(orders: IOrder[]): AppOrderState[] {
    return [
        ...new Set(
            orders.map(
                (order: IOrder): AppOrderState => {
                    if (order.isUrgent) return LocalOrderState.URGENT;
                    if (order.isLate) return LocalOrderState.LATE;
                    // same label is used for refused and cancelled orders
                    if (order.state === OrderState.REFUSED) return OrderState.CANCELLED;
                    return order.state as AppOrderState;
                }
            )
        ),
    ];
}

export function isLate(order: IOrder): boolean {
    return order.state === OrderState.READY && getMinutesLeft(order.withdrawRange[1]) <= -15;
}

export function isUrgent(order: IOrder): boolean {
    return (
        (order.state === OrderState.ACCEPTED || order.state === OrderState.PREPARING) &&
        getMinutesLeft(order.withdrawRange[0]) <= 15
    );
}

export function getOrderCount(tabsCounters: ordersStatesCounters_ordersStatesCounters): number {

    if (tabsCounters && Object.keys(tabsCounters).length) {
        return tabsCounters[TabKey.PREPARE] + tabsCounters[TabKey.DISTRIBUTE];
    }

    return 0;
}

export function getFormattedOrders(
    orders: listOrders_orders,
    cancellableUpdatingOrderIds?: any,
    updatingOrderIds?: any
): any {
    return (get(orders, 'edges') || [])
        .map(edge => edge.node as listOrders_orders_edges_node)
        .map(o => {
            const dates = o.withdrawRange.split('/');
            return {
                ...o,
                updated: new Date(o.updated),
                withdrawRange: [new Date(dates[0]), new Date(dates[1])] as [Date, Date],
                state: o.state as OrderState,
                tableNumber: o.tableNumber as number | null,
            };
        })
        // @ts-ignore
        .map((o: IOrder) => {
            const order = {
                ...o,
                // display previous state while animations are ongoing
                state: (((updatingOrderIds && updatingOrderIds.includes(o.id)) ||
                    (cancellableUpdatingOrderIds && cancellableUpdatingOrderIds.includes(o.id))) &&
                orderStateUpdates[o.state][3]
                    ? orderStateUpdates[o.state][3]
                    : o.state) as OrderState,
            };
            return {
                ...order,
                isLate: isLate(order),
                isUrgent: isUrgent(order),
            };
        })
        .sort((order1: IOrder, order2: IOrder) => {
            const order1Time = order1.withdrawRange[0].getTime();
            const order2Time = order2.withdrawRange[0].getTime();
            return order1Time > order2Time ? 1 : -1;
        });
}

export function getItemsFamily(
    items: listOrders_orders_edges_node_orderItems[] | listOrders_orders_edges_node_orderItemsFormula[],
    defaultType: string
): {
    [key: string]: listOrders_orders_edges_node_orderItems[] | listOrders_orders_edges_node_orderItemsFormula[];
} {
    // @ts-ignore
    return items.reduce((m: listOrders_orders_edges_node_orderItems[] | listOrders_orders_edges_node_orderItemsFormula[], item: any) => {
        if (!item.offerItem || !item.offerItem.inheritedFamily) {
            m[defaultType] = m[defaultType] || [];
            m[defaultType].push(item);
        } else {
            m[item.offerItem.inheritedFamily] = m[item.offerItem!.inheritedFamily] || [];
            // @ts-ignore
            m[item.offerItem.inheritedFamily].push(item);
        }
        return m;
    }, {});
}

export function makeNumericId(id: string, assertType?: string): number {
    if (process.env.NODE_ENV === 'development') {
        return parseInt(id.split(':')[1]);
    } 
    const info = Buffer.from(id, 'base64')
        .toString()
        .split(':');
        
    return parseInt(info[1]);
}

export function getFormattedOrderId(id: any) {
    return makeNumericId(id, 'id: ').toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const moveOrderToLocalOrderList = (currentTab, orderUpdated, setOrder, ordersList, setOrders, setCountersAtTab, counters) => {

    const { id: orderId } = orderUpdated;

    if (tabStates[currentTab].includes(orderUpdated.state)) {
        // current tab allows the order in the new state, no need to move the order to another list
        setOrder(currentTab, orderId, {
            ...orderUpdated,
            isLate: isLate(orderUpdated),
            isUrgent: isUrgent(orderUpdated)
        });

        return;
    }

    // remove order from current list - since current tab should contain the order in the new state
    const index = ordersList[currentTab].findIndex((myOrder) => myOrder.id === orderId);
    ordersList[currentTab].splice(index, 1);
    setOrders(currentTab, ordersList[currentTab]);

    // move order in new state to the respective tab
    let newTab: any = Object.entries(tabStates).find(([key, value]) => value.includes(orderUpdated.state));

    // immediately reflect changes on counters
    setCountersAtTab(currentTab, counters[currentTab] - 1);
    if (!newTab) {
        return;
    }

    newTab = newTab[0];

    setOrders(newTab, [...ordersList[newTab], {
        ...orderUpdated,
        isLate: isLate(orderUpdated),
        isUrgent: isUrgent(orderUpdated)
    }]);

    setCountersAtTab(newTab, counters[newTab] + 1);
}

export const revertMovingOrderToLocalOrderList = (previousOrder, orderUpdated, ordersList, setOrders, setCountersAtTab, counters) => {

    // move order in new state to the respective tab
    const orderInTab = Object.entries(tabStates).find(([key, value]) => value.includes(previousOrder.state))?.[0] || 0;
    const moveOrderToTab = Object.entries(tabStates).find(([key, value]) => value.includes(orderUpdated.state))?.[0] || 0;

    setCountersAtTab(orderInTab, counters[orderInTab]);
    setCountersAtTab(moveOrderToTab, counters[moveOrderToTab]);

    setOrders(orderInTab, ordersList[orderInTab]);
    setOrders(moveOrderToTab, ordersList[moveOrderToTab]);
}

export const formatFormulaOrders = ( orderItemsFormula: listOrders_orders_edges_node_orderItemsFormula[] ) => orderItemsFormula && orderItemsFormula.filter(
  eachFormulaItem => eachFormulaItem.stepNumber === null
)
.map(eachParentFormula => ({
  ...eachParentFormula,
  totalPriceWhenAdded: eachParentFormula.priceWhenAdded,
  subItems: orderItemsFormula && orderItemsFormula.filter(
    eachSubProduct => (eachSubProduct.stepNumber !== null && eachSubProduct.stepNumber >= 0) && (eachSubProduct.orderItemFormulaParent && eachSubProduct.orderItemFormulaParent.id) === eachParentFormula.id
  ),
}))
import React from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import { useTransition, animated } from '@react-spring/web';

import Portal from './Portal';

const ModalWrapper = styled.section`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    z-index: ${({ theme }) => theme.zIndex.modalOverlay};
    &.blocking {
        z-index: ${({ theme }) => theme.zIndex.blockingModalOverlay};
    }
`;

const ModalCard = animated(styled.div`
    height: 33vh;
    width: 33vw;
    color: ${({ theme }) => theme.palette.text.medium};
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 12px;
    z-index: 1;
    margin: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 60px;
`);

const Background = animated(styled.div`
    position: relative;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.palette.common.black};
`);

interface IProps {
    isOpen: boolean;
    closeModal?: any;
    theme: DefaultTheme;
    children: any;
}

const Modal = (props: IProps) => {
    const { children, isOpen, theme } = props;
    const pointerEvents = isOpen ? 'all' : 'none';
    const transitions = useTransition(isOpen, {
        config:{ duration: 250 },
        from:{ opacity: 0 },
        enter:{ opacity: 1 },
        leave:{ opacity: 0 },
    })

    return transitions((style, isOpen) => (
            <Portal>
                {isOpen && <ModalWrapper>
                    <Background
                        style={{
                            pointerEvents,
                            backgroundColor: theme.palette.background.darkOpacity,
                            opacity: style.opacity,
                            zIndex: 9998
                        }}
                    />
                    <ModalCard style={{ zIndex: 9999 }}>{children}</ModalCard>
                </ModalWrapper>}
            </Portal>
    ));
}

export default withTheme(Modal);

// @ts-nocheck
import React, { Suspense } from 'react';

import { ApolloProvider } from '@apollo/client';
import styled, { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { appTheme, GlobalStyle } from 'styles/theme';
import { client } from 'services/apollo/client';
import AppContext from 'AppContext';
import { Loader, ToastCloseButton } from 'components';
import Router from './Router';

import 'react-toastify/dist/ReactToastify.css';

const StyledLoader = styled(Loader)`
    height: 100vh;
`;

const App = () => (
    <ThemeProvider theme={appTheme}>
        <AppContext.Component>
            <ApolloProvider client={client}>
                <GlobalStyle />
                <ToastContainer 
                    hideProgressBar={true} 
                    toastClassName="app-toast" 
                    className='app-toast-container'
                    closeButton={<ToastCloseButton />}
                    />
                <Suspense fallback={<StyledLoader />}>
                    <Router />
                </Suspense>
            </ApolloProvider>
        </AppContext.Component>
    </ThemeProvider>
);

export default App;

import React, { useContext, useEffect, useMemo } from 'react';
import AppContext from 'AppContext';
import { loader } from 'graphql.macro';
import DateOfferSelectorComponent from './DateOfferSelectorComponent';
import { useQuery } from '@apollo/client';
import { makeNumericId } from 'services/orderService';
import isEqual from 'lodash/isEqual';
import config from 'config.json';
const countOrders = loader('../../../../../queries/countOrders.graphql');
interface IProps {
    className?: string;
    offersWithOrders: any[];
}

const DayOfferSelector = ({ className, offersWithOrders }: IProps) => {
    const { offerDate, setOfferDate, shouldFetchNewOfferOrders, cleanOrdersAndCounters } = useContext(AppContext.Context);
    // eslint-disable-next-line
    let offerIdList: string[] = offersWithOrders.map(( { value } ) => value).flat();

    const { data, loading } = useQuery(countOrders, {
        variables: {
            idOffers: offerIdList
        },
        skip: !offersWithOrders || offersWithOrders.length === 0,
        pollInterval: config.ORDER_COUNTER_POLLING_INTERVAL,
        fetchPolicy: 'network-only',
    });

    const offersWithOrdersAndCount: any = useMemo(() => {
        if(!data) return [];
        const offersWithOrdersAndCount: any = []
        offersWithOrders.forEach(({ label, value }) => {
            const filtredValues =  value.filter((idOffer: string) => {
                return data.listOffersWithOrdersCount.some((offerWithOrderCount)  => offerWithOrderCount.id === makeNumericId(idOffer))
            });
            const count = data.listOffersWithOrdersCount.reduce((count, offerWithOrderCount) => {
                if(filtredValues.some(idOffer => offerWithOrderCount.id === makeNumericId(idOffer))){
                    return count + offerWithOrderCount.count;
                };
                return count
            }, 0);
            offersWithOrdersAndCount.push(({
                label: `${label} (${count})`,
                value: filtredValues,
            }))
        });
        return offersWithOrdersAndCount.filter(({ value }) => value.length > 0);
    }, [offersWithOrders, data, offerDate])


    const isFirstOfferForToday = offersWithOrdersAndCount.length > 0
        && new Date(offersWithOrdersAndCount[0].label).toLocaleDateString() ===
        new Date().toLocaleDateString();
    const ordersFromOtherDatesAvailable = (offersWithOrdersAndCount.length > 0 && !isFirstOfferForToday) ||
        offersWithOrdersAndCount.length > 1;

    useEffect(() => {
        if (ordersFromOtherDatesAvailable && !offerDate) {
            setOfferDate(offersWithOrdersAndCount[0].value);
        }
    }, [offersWithOrdersAndCount, offerDate, setOfferDate]);

    const handleOfferDate = ({ value }: any) => {
        if(isEqual(value, offerDate)) return;
        setOfferDate(value);
        shouldFetchNewOfferOrders(true);
        cleanOrdersAndCounters();
    };

    if (!offersWithOrders || offersWithOrders.length === 0) {
        return null;
    }

    if (loading) {
        return null;
    }

    if (ordersFromOtherDatesAvailable) {
        return (
            <DateOfferSelectorComponent
                className={className}
                selectedOffer={offersWithOrdersAndCount[0]}
                options={offersWithOrdersAndCount}
                handleChange={handleOfferDate}
            />
        );
    }
    return null;
};

export default DayOfferSelector;

import React, { useContext } from 'react';
import AppContext from 'AppContext';
import { ReactComponent as PrintIcon } from 'ressources/img/print.svg';
import { ReactComponent as PrintIconKO } from 'ressources/img/printer-ko.svg';

interface IProps {
    className?: string;
}

const Print = ({ className }: IProps) => {
    const { printer: { setModalOpen, isModalOpen, selected } } = useContext(AppContext.Context);
    const Icon = selected ? PrintIcon : PrintIconKO;
    return (
        <Icon className={className} onClick={() => { setModalOpen(!isModalOpen) }} />
    );
}

export default Print;

import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getOrderTabLists, tabStates } from 'services/orderService';
import { IOrder } from 'types/order';
import { TabKey } from 'types/tab';
import { getOrderStates } from 'services/orderService';
import EmptyList, { OrderListKey } from './EmptyList';
import { OrderModal, StateTransitionCard, Card, StateLabel } from 'components/Order';
import { PrinterModal } from 'components/Printer';
import AppContext from 'AppContext';
import { version as projectVersion } from 'services/projectVersion';
import { ordersStatesCounters_ordersStatesCounters } from '../../../types/graphql/ordersStatesCounters';
import { getQuerySearch, getQuerySearchByTab } from '../../../services/queryService';
import get from 'lodash/get';
import { Loader } from '../../../components';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const Wrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.paddingL}px;
`;

const TabHeader = styled.div`
    height: ${({ theme }) => theme.height.tabHeader}px;
    display: flex;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing.margin}px;
    background-color: ${({ theme }) => theme.palette.background.dark};
    border-radius: 6px 6px 6px 6px;
`;

const TabHeaderContent = styled.ul`
    position: relative;
    display: flex;
`;

const TitleContainer = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    opacity: 0.6;
    &.active {
        opacity: 1;
    }
`;

const Title = styled.span`
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
`;

interface BadgeProps {
    readonly tab: TabKey;
}
const Badge = styled.span<BadgeProps>`
    height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${({ theme }) => theme.spacing.margin / 2}px;
    border-radius: 50%;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    background-color: ${({ theme, tab }) => theme.palette.background.badge[tab]};
`;

interface BorderProps {
    readonly tabIndex: number;
}

const ActiveTabBorder = styled.span<BorderProps>`
    position: absolute;
    height: 7px;
    width: 250px;
    left: ${({ tabIndex }) => tabIndex * 250}px;
    bottom: 0;
    outline: none;
    border-radius: 6px 6px 0 0;
    background: ${({ theme }) => theme.palette.common.blue};
    transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

interface MainContentProps {
    readonly searchDisplay?: boolean;
}

const MainContent = styled.div<MainContentProps>`
    padding: 0px ${({ theme }) => theme.spacing.paddingL}px;
    height: calc(
        100vh -
            ${({
                theme: {
                    height: { header, tabHeader },
                    spacing: { margin, paddingL },
                },
                searchDisplay,
            }) => header + 2 * paddingL + (searchDisplay ? 0 : tabHeader + margin)}px
    );
    display: flex;
    flex-direction: column;
`;

const CardsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    height: 100%;
    overflow-y: auto;
`;

const LabelContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: ${({ theme }) => theme.spacing.margin}px;
    flex: 1;
`;

const StyledLabel = styled(StateLabel)`
    margin-right: ${({ theme }) => theme.spacing.unit}px;
    &:last-child {
        margin-right: 0;
    }
`;

interface IProps {
    orders: IOrder[];
    tabsCounters: ordersStatesCounters_ordersStatesCounters
}

function getTabIndex(key: TabKey): number {
    switch (key) {
        case TabKey.DISTRIBUTE:
            return 1;

        case TabKey.DISTRIBUTED:
            return 2;

        case TabKey.CANCELLED:
            return 3;

        default:
            return 0;
    }
}


const Content = ({ orders, tabsCounters, t }: IProps & WithTranslation) => {
    const {
        updatingOrderIds,
        searchDisplay,
        printer: { isModalOpen: isPrinterModalOpen, setModalOpen: setPrinterModalOpen },
        home: { currentTab, setCurrentTab },
        refetchOrders,
        offers,
        offerDate,
        orders: ordersList,
        counters,
        setFetchingNextOrders,
        fetchingNextOrders,
        setIsOrdersLoading
    } = useContext(AppContext.Context);

    const [typeTimeout, setTypeTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState('');

    const selectTab = (tab: TabKey) => {
        setCurrentTab(tab);

        if (typeTimeout !== null) {
            // clear previous timer
            clearTimeout(typeTimeout);
            setTypeTimeout(null);
        }

        setTypeTimeout(
            setTimeout(() => {
                const offerIds = offerDate ? offerDate : (offers || []).map(({ id }) => id);
                if (refetchOrders && ordersList[tab].length !== counters[tab] && ordersList[tab].length < 100) {
                    setIsOrdersLoading(true);
                    // @ts-ignore
                    const querySearch = getQuerySearch(offerIds);
                    const statesQuery = querySearch.find((item) => item.key === 'state');
                    if(statesQuery) statesQuery.value = tabStates[tab].join();

                    refetchOrders({
                        defaultQuerySearch: querySearch
                    });
                }
            }, 500)
        );
    };

    const openModal = (order: IOrder): void => {
        setModalOpen(true);
        setSelectedOrderId(order.id);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const getOrders = (): [IOrder[], number, number, number, number] => {
        if (searchDisplay) return [orders, 0, 0, 0, 0];
        const [ordersToPrepare, ordersToDistribute, ordersDistributed, cancelledOrders] = getOrderTabLists(orders, ordersList);
        let currentOrders = ordersToPrepare;
        if (currentTab === TabKey.DISTRIBUTE) {
            currentOrders = ordersToDistribute;
        } else if (currentTab === TabKey.DISTRIBUTED) {
            currentOrders = ordersDistributed;
        } else if (currentTab === TabKey.CANCELLED) {
            currentOrders = cancelledOrders;
        }

        if (tabsCounters) {
            return [currentOrders, tabsCounters[TabKey.PREPARE], tabsCounters[TabKey.DISTRIBUTE], tabsCounters[TabKey.DISTRIBUTED], tabsCounters[TabKey.CANCELLED]];
        }
        return [currentOrders, ordersToPrepare.length, ordersToDistribute.length, ordersDistributed.length, cancelledOrders.length];
    };

    const [displayedOrders, toPrepareCount, toDistributeCount, distributedCount, cancelledCount] = getOrders();
    const tabTitles = [
        { key: TabKey.PREPARE, count: toPrepareCount },
        { key: TabKey.DISTRIBUTE, count: toDistributeCount },
        { key: TabKey.DISTRIBUTED, count: distributedCount },
        { key: TabKey.CANCELLED, count: cancelledCount },
    ];
    const currentTabIndex = getTabIndex(currentTab);

    const hasNextPage = tabTitles[currentTabIndex].count > displayedOrders.length;

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading: fetchingNextOrders,
      hasNextPage,
      onLoadMore: () => {
          if (refetchOrders) {
              const offerIds = offerDate ? offerDate : offers;
              // @ts-ignore
              const querySearch = getQuerySearchByTab(offerIds, currentTab);

              const order = ordersList[currentTab][ordersList[currentTab].length - 1];
              // to prevent fetching the last seen order

              if (!order || !order.withdrawRange || ordersList[currentTab].length < 100) {
                  return;
              }

              const lastOrderWithdrawal = order.withdrawRange;
              const withdrawalTime = lastOrderWithdrawal[0];
              // add a millisecond to prevent fetching the last order seen
              withdrawalTime.setMilliseconds(lastOrderWithdrawal[0].getMilliseconds() + 1);
              querySearch.push({ key: 'afterWithdrawalTime', operator: '>',
                  value: lastOrderWithdrawal[0].toISOString()
              } );

              setFetchingNextOrders(true);
              refetchOrders({
                  defaultQuerySearch: querySearch
              });
          }
      },
      disabled: false,
  });

    return (
        <Wrapper>
            {!searchDisplay && (
                <TabHeader>
                    <TabHeaderContent>
                        {tabTitles.map(({ key, count }) => (
                            <TitleContainer
                                key={key}
                                onClick={() => {
                                    selectTab(key)
                                }}
                                className={currentTab === key ? 'active' : ''}
                            >
                                <Title>{t(`page:home.tab.${key}`)}</Title>
                                <Badge tab={key}>{count}</Badge>
                            </TitleContainer>
                        ))}
                        <ActiveTabBorder tabIndex={currentTabIndex} />
                    </TabHeaderContent>
                </TabHeader>
            )}
            <MainContent searchDisplay={searchDisplay} id={'my-id'}>
                {displayedOrders.length > 0 ? (
                    <>
                        <CardsContainer ref={rootRef}>
                            {displayedOrders.map((order, index) => {
                                if (updatingOrderIds.includes(order.id)) {
                                    return <StateTransitionCard key={order.id} order={order} />;
                                } else {
                                    return <Card onClick={() => openModal(order)} key={order.id} order={order} />;
                                }
                            })}
                            <div ref={sentryRef}>
                              {fetchingNextOrders && (
                                <StyledLoader/>
                              )}
                            </div>
                        </CardsContainer>
                        <LabelContainer>
                            {getOrderStates(displayedOrders).map(label => (
                                <StyledLabel key={label} state={label} />
                            ))}
                        </LabelContainer>
                    </>
                ) : (
                    <EmptyList type={searchDisplay ? OrderListKey.SEARCH : currentTab} />
                )}
            </MainContent>
            <AppVersionWrapper>
                <span>v{projectVersion}</span>
            </AppVersionWrapper>
            {selectedOrderId && (
                <OrderModal
                    isOpen={isModalOpen}
                    closeModal={closeModal}
                    order={displayedOrders.find(o => o.id === selectedOrderId)}
                />
            )}
            <PrinterModal isOpen={isPrinterModalOpen} closeModal={setPrinterModalOpen} />
        </Wrapper>
    );
};

const AppVersionWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #5F7081
`;
const StyledLoader = styled(Loader)`
    height: calc(100vh - ${({ theme }) => theme.height.header}px);
    justify-content: flex-start;
    align-items: flex-start;
`;

export default withTranslation()(Content);

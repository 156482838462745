import React from 'react';
import { Select } from 'components';
import { IOffer, IOfferTemplate } from 'types/order';

interface IProps {
    className?: string;
    selectedOffers: IOffer[] | IOfferTemplate[] | undefined;
    options: IOffer[] | IOfferTemplate[];
    handleChange: (offer: any) => void;
}

const OfferSelectorComponent = ({ className, selectedOffers, options, handleChange }: IProps) => (
    <div className={className}>
        <Select
            isMulti={true}
            selectedOption={selectedOffers}
            options={options}
            handleChange={handleChange}
        />
    </div>
);

export default OfferSelectorComponent;

import React from 'react';

import Auth from 'services/auth0';
import { ReactComponent as LogoutIcon } from 'ressources/img/off.svg';

interface IProps {
    className?: string;
}

const Logout = ({ className }: IProps) => <LogoutIcon className={className} onClick={Auth.signOut} />;

export default Logout;

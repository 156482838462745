import { keyframes, DefaultTheme } from 'styled-components';

export function fadeOut(props: { theme: DefaultTheme }) {
    return keyframes`
    0% {
        opacity: 1;
        width: ${props.theme.width.orderCard}px;
    }
    50% {
        opacity: 0;
        width: ${props.theme.width.orderCard}px;
    }
    100% {
        opacity: 0;
        width: 0px;
    }
    `;
}

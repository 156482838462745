import React, { Component } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { Loader, PINModalTrigger, TouchDetector } from 'components';
import Auth from 'services/auth0';
import CallBack from 'components/Auth/CallBack';
import Login from 'components/Auth/Login';
import Home from 'pages/Home';

const StyledLoader = styled(Loader)`
    margin-top: ${({ theme }) => theme.height.header}px;
    height: calc(100vh - ${({ theme }) => theme.height.header}px);
`;

const PrivateRoute = () => {
    if (!Auth.isAuthenticated()) {
        return <Navigate to="/login" />
    }

    return <Outlet />
};

class RouterComponent extends Component<{}, { loading: boolean }> {
    state = { loading: true };
    async componentDidMount() {
        try {
            this.setState({ loading: true });
            await Auth.silentAuth();
        } catch (err: ReturnType<any>) {
            if (err.error !== 'login_required') console.log(err.error);
        }
        this.setState({ loading: false });
    }
    render() {
        return (
            <Router>
                <PINModalTrigger />
                <TouchDetector>
                    {!this.state.loading ? (
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/callback" element={<CallBack />} />
                            <Route path='/' element={<PrivateRoute />}>
                                <Route index element={<Home />} path="/" />
                            </Route>
                        </Routes>
                    ) : (
                        <StyledLoader />
                    )}
                </TouchDetector>
            </Router>
        );
    }
}

export default RouterComponent;

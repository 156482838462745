import React from 'react';
import jsQR, { QRCode } from 'jsqr';

type WorkerMessage = {
    code: QRCode;
    image: ImageData;
};

interface IProps {
    width: number;
    height: number;
    webcam?: MediaTrackConstraints;
    onDetect?: (data: string) => void;
}

interface IState {
    result: WorkerMessage | null;
}

export default class Qr extends React.Component<IProps, IState> {
    state = { result: null };
    video: HTMLVideoElement;
    canvas: HTMLCanvasElement | null = null;
    track: MediaStreamTrack | null = null;
    c2d: CanvasRenderingContext2D | null = null;
    live: number | null = null;

    constructor(props: IProps) {
        super(props);
        this.video = document.createElement('video');
    }
    componentDidMount() {
        this.startCapture();
    }

    componentWillUnmount = () => {
        this.stopCapture();
    };

    startCapture = () => {
        if (this.live != null) return;
        navigator.mediaDevices
            .getUserMedia({ video: this.props.webcam || { facingMode: { exact: 'environment' } } })
            .then(stream => {
                this.setState({ result: null }, () => {
                    const tracks = stream.getVideoTracks();
                    this.track = tracks.length ? tracks[0] : null;

                    this.video.srcObject = stream;
                    this.video.play();
                    if (this.state.result == null && this.live == null)
                        this.live = requestAnimationFrame(this.getImage);
                });
            });
    };
    stopCapture = () => {
        if (this.live) cancelAnimationFrame(this.live);
        this.live = null;
        if (this.track) this.track.stop();
    };

    getResult = (event: { data: WorkerMessage }) => {
        if (this.state.result != null) return;
        this.setState(
            {
                result: { code: event.data.code, image: event.data.image },
            },
            () => {
                const [w, h] = [this.props.width, this.props.height];
                // this.props.onDetect && this.props.onDetect(event.data.code.data);
                // this.props.onDetect && this.props.onDetect('T3JkZXI6Ng==');
                this.props.onDetect && this.props.onDetect('T3JkZXI6Ng==');
                this.stopCapture();
                createImageBitmap(event.data.image, 0, 0, w, h).then(
                    im => this.c2d && this.c2d.drawImage(im, 0, 0, w, h)
                );
            }
        );
    };

    getImage = () => {
        console.log('getImage', this.live);
        if (this.video.readyState === this.video.HAVE_ENOUGH_DATA && this.c2d && this.live) {
            const [w, h] = [this.props.width, this.props.height];
            this.c2d.drawImage(this.video, 0, 0, w, h);
            const data = this.c2d.getImageData(0, 0, w, h);
            this.detect(data);
        }
        if (this.state.result == null && this.live != null) requestAnimationFrame(this.getImage);
    };

    detect = (data: ImageData) => {
        const qr = jsQR(data.data, data.width, data.height, {
            inversionAttempts: 'dontInvert',
        });
        if (qr) {
            this.getResult({ data: { code: qr, image: data } });
        }
    };

    render() {
        return (
            <canvas
                width={this.props.width}
                height={this.props.height}
                ref={ref => {
                    this.canvas = ref;
                    if (ref) this.c2d = ref.getContext('2d');
                }}
            />
        );
    }
}

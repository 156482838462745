import React from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { labelIcons } from 'services/orderService';
import { AppOrderState } from 'types/order';

const Icon = styled.span`
    position: absolute;
    left: ${({ theme }) => theme.spacing.unit * 3}px;
    height: 28px;
`;

interface WrapperProps {
    readonly state: AppOrderState;
}

const Wrapper = styled.div<WrapperProps>`
    position: relative;
    min-width: ${({ theme }) => theme.width.stateLabel}px;
    height: ${({ theme }) => theme.height.stateLabel}px;
    display: flex;
    align-items: center;
    background: ${({ theme, state }) => theme.palette.background.order[state]};
    border-radius: 6px;
    color: ${({ theme, state }) => theme.palette.text.order[state]};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const Title = styled.span`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface IProps {
    className?: string;
    state: AppOrderState;
}

function OrderStateLabel({ className, state, t }: IProps & WithTranslation) {
    return (
        <Wrapper className={className} state={state}>
            {labelIcons[state] && <Icon>{labelIcons[state]}</Icon>}
            <Title>{t(`schema:order.states.${state}`)}</Title>
        </Wrapper>
    );
}

export default withTranslation()(OrderStateLabel);

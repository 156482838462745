import React from 'react';
import { ErrorResponse } from '@apollo/client/link/error';
import { ApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { toast } from 'react-toastify';

import { Toast } from 'components';
import { ErrorType } from 'components/Toast';
import { NormalizedCacheObject } from '@apollo/client/cache';

const getNetworkStatus = loader('../../queries/getNetworkStatus.graphql');

const onError = (error: ErrorResponse, client: ApolloClient<NormalizedCacheObject>): void => {
    switch (error.operation.operationName) {
        case 'changeOrderState':
            toast(<Toast type={ErrorType.UPDATE} />);
            break;
        case 'listOrders':
            const { querySearch } = error.operation.variables;
            if (querySearch && querySearch.length > 0 && querySearch[0].key === 'search') {
                toast(<Toast type={ErrorType.SEARCH} />);
            } else {
                toast(<Toast type={ErrorType.NETWORK} />);
            }
            break;
        case 'listOffers':
            toast(<Toast type={ErrorType.NETWORK} />);
            break;
        default:
            break;
    }
    if (error.graphQLErrors) {
        error.graphQLErrors.forEach((graphQLError: GraphQLError): void => {
            switch (graphQLError.message) {
                case 'AUTH_REQUIRED':
                case 'EXPIRED_AUTH':
                case 'INVALID_AUTH':
                    console.log('graphQLError', graphQLError.message);
                    window.location.href = '/login';
                    break;
                default:
                    console.log('graphQLError', graphQLError.message);
                    break;
            }
        });
    } else if (error.networkError) {
        // todo - check read from cache network status query result
        client.readQuery({
            query: getNetworkStatus,
        });
        /*const networkStatus = client.readQuery({ query: getNetworkStatus });
        if (!networkStatus.isOffline) {
            client.writeData({ data: { isOffline: true } });
        }*/
    }
};

export default { onError };

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV === 'development',
        lng: 'fr',
        fallbackLng: 'fr',
        ns: ['page', 'component', 'schema', 'app'],
        defaultNS: 'page',
        interpolation: {
            escapeValue: false,
        }
    });

export default i18next;

import styled from 'styled-components';
export const headerHeight = 84;
export const paddingBottom = 50;

export const Header = styled.h3`
    height: ${headerHeight}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: ${({ theme }) => theme.spacing.paddingL}px;
    flex-shrink: 0;
    color: ${({ theme }) => theme.palette.text.light};
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
    background-color: ${({ theme }) => theme.palette.background.lightDark};
    text-transform: uppercase;
`;

export const Close = styled.button`
    height: 100%;
    width: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-size: ${({ theme }) => theme.typography.fontSizeXXL}px;
`;

export const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${({ theme }) => theme.spacing.paddingL}px;
    padding-bottom: ${paddingBottom}px;
    background-color: ${({ theme }) => theme.palette.background.light};
    padding: 30px;
`;

export const Input = styled.input`
    height: 52px;
    width: 360px;
    border: 1px solid ${({ theme }) => theme.palette.grey[3]};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: 16px;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${({ theme }) => theme.palette.text.medium};
`;

export const Jumbo = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 0px 40px 0px;
    justify-content: left;
    align-items: center;
    align-content: center;
`;

export const PrinterImgIcon = styled.div`
    padding: 25px;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 100%;
    margin-right: 20px;
    svg #Group {
        stroke: ${({ theme }) => theme.palette.common.black};
    }
`;

export const Title = styled.h1`
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
    color: ${({ theme }) => theme.palette.text.medium};
`;

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    align-items: center;
`;

interface IPropsText {
    color?: 'light' | 'medium' | 'dark' | 'extraDark' | 'error';
    bold?: boolean;
    size?: 'S' | 'M' | 'L' | 'XL' | 'XXL';
}
export const Text = styled.span<IPropsText>`
    font-size: ${({ size = 'S', theme }) => theme.typography.size[size]}px;
    line-height: ${({ size = 'S', theme }) => theme.typography.lineHeight[size]}px;
    color: ${({ color = 'medium', theme }) => theme.palette.text[color]};
    font-weight: ${({ bold }) => (bold ? '600' : '300')};
    display: flex;
`;

export const Label = styled(Text)`
    padding-bottom: 14px;
`;

export const InputWrapper = styled.div`
    flex-direction: column;
    display: flex;
    position: relative;
`;

export const PrinterListWrapper = styled.div`
    margin-top: 40px;
    flex-direction: column;
    display: flex;
`;

export const PrinterListItemWrapper = styled.div`
    height: 74px;
    width: 803.82px;
    border-radius: 7.2px;
    background-color: ${({ theme }) => theme.palette.common.white};
    box-shadow: 0 0 10px -4px rgba(65, 57, 107, 0.2);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 25px;
`;

export const PrinterInfo = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
`;

import React, { useContext } from 'react';
import styled from 'styled-components';

import AppContext from 'AppContext';

const TouchDetector = ({ children }: any) => {
    const {
        idleTime: { isModalOpen, setModalOpen, hasExpired },
    } = useContext(AppContext.Context);

    const onTouch = () => {
        if (hasExpired && !isModalOpen) setModalOpen(true);
    };

    return (
        <div>
            {hasExpired && <Overlay onTouchStart={onTouch} />}
            {children}
        </div>
    );
};

const Overlay = styled.div`
    background: transparent;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: ${({ theme }) => theme.zIndex.idleOverlay};
`;

export default TouchDetector;

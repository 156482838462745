import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import config from 'config.json';
import { QuerySearchInput } from 'types/graphql/globalTypes';
import { listOrders } from 'types/graphql/listOrders';
import { getQuerySearch, getQuerySearchByTab } from 'services/queryService';
import AppContext from 'AppContext';
import PageContent, { Component } from './PageContent';
import { ordersStatesCounters } from '../../types/graphql/ordersStatesCounters';
import { TabKey } from '../../types/tab';
import { getFormattedOrders, getOrderCount, tabCounterKey, tabStates } from '../../services/orderService';
import { getSetItem, LocalStorageKey, setItem } from '../../services/localStorageService';
import { IOffer, OrderState } from '../../types/order';

const listOrdersQuery = loader('../../queries/listOrders.graphql');
const ordersStatesCountersQuery = loader('../../queries/ordersStatesCounters.graphql');

const getSearchQuerySearch = (offerIds: string[], searchDisplay: boolean, searchValue: string): QuerySearchInput[] => {
    let searchQuerySearch = [] as QuerySearchInput[];
    if (searchDisplay) {
        searchQuerySearch =
            searchValue.length >= 3 ? getQuerySearch(offerIds, true, searchValue) : getQuerySearch(offerIds, true);
    }
    return searchQuerySearch;
};

const playNotification = () => {
    const audio = new Audio(`${process.env.PUBLIC_URL}beep.mp3`);
    audio.loop = false;
    audio.play().catch(e => console.log(e));
};

const onCompleted = (data: listOrders, setOrders, currentTab, fetchingNextOrders, setFetchingNextOrders, orders) => {
    if (!data || !data.orders) {
        return;
    }

    const formattedOrders = getFormattedOrders(data.orders);

    if(fetchingNextOrders) {
        const ordersUpdated = orders[currentTab];

        ordersUpdated.push(formattedOrders);
        setOrders(currentTab, ordersUpdated.flat());
        setFetchingNextOrders(false);
        return;
    }
    setOrders(currentTab, formattedOrders);


    const persistedOrderIds = getSetItem(LocalStorageKey.ORDER_IDS);
    const newOrderList = formattedOrders.filter(({ id, state }) => {
        if (state === OrderState.ACCEPTED && !persistedOrderIds.has(id)) {
            persistedOrderIds.add(id);
            return true;
        }
        return false;
    });

    if (newOrderList.length) {
        setItem(LocalStorageKey.ORDER_IDS, persistedOrderIds);
        playNotification();
    }
};

const onCountersQueryCompleted = (data: ordersStatesCounters, counters, setCounters, refetchOrders, currentTab,
                                  setRefetchOrders, offerDate, offers: IOffer[], setRefetchLoading, orders,
                                  shouldFetchNewOfferOrders, fetchNewOfferOrders, offerIds) => {
    if (!data || !data.ordersStatesCounters) {
        return;
    }

    setRefetchOrders(refetchOrders);

    if(counters[currentTab] === 0 && data.ordersStatesCounters[tabCounterKey[currentTab]] !== counters[currentTab]){
      setRefetchLoading(true);
    }

    if(currentTab != null && (
        fetchNewOfferOrders ||
        data.ordersStatesCounters[tabCounterKey[currentTab]] !== counters[currentTab] || (orders[currentTab].length < 100 && data.ordersStatesCounters[tabCounterKey[currentTab]] >= 100))
    ) {
        const offerIds = offerDate ? offerDate : offers.map(({ id }) => id);

        const querySearch = getQuerySearch(offerIds);
        const statesQuery = querySearch.find((item) => item.key === 'state');
        if(statesQuery) statesQuery.value = tabStates[currentTab].join();

        refetchOrders({
          defaultQuerySearch: querySearch
        });

        shouldFetchNewOfferOrders(false);
    }

    setCounters({
        [TabKey.PREPARE]: data.ordersStatesCounters[tabCounterKey.PREPARE],
        [TabKey.DISTRIBUTE]: data.ordersStatesCounters[tabCounterKey.DISTRIBUTE],
        [TabKey.DISTRIBUTED]: data.ordersStatesCounters[tabCounterKey.DISTRIBUTED],
        [TabKey.CANCELLED]: data.ordersStatesCounters[tabCounterKey.CANCELLED],
    });
};

const isCountersEmpty = counters => Object.keys(counters).every(eachCounter => counters[eachCounter] === 0);

const Home = () => {
    const {
        offers,
        offerDate,
        searchValue,
        searchDisplay,
        home: { currentTab }, setRefetchOrders,
        counters, setCounters, setOrders, fetchingNextOrders, setFetchingNextOrders, orders,
        isOrdersLoading, setIsOrdersLoading,
        shouldFetchNewOfferOrders, fetchNewOfferOrders
    } = useContext(AppContext.Context);
    const offerIds = offerDate ? offerDate : offers ?  offers.map(({ id }) => id) as string[] : [];
    const { loading, error, data, networkStatus, refetch } = useQuery(listOrdersQuery, {
        variables: {
            // @ts-ignore
            defaultQuerySearch: getQuerySearchByTab(offerIds, currentTab),
            // @ts-ignore
            searchQuerySearch: getSearchQuerySearch(offerIds, searchDisplay, searchValue),
            withSearch: searchDisplay,
        },
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        onCompleted: (data) => {
            onCompleted(data, setOrders, currentTab, fetchingNextOrders, setFetchingNextOrders, orders);
        },
        skip: isEmpty(offerIds) || !isCountersEmpty(counters),
        notifyOnNetworkStatusChange: true,
        context: {
            debounceKey: 'getOrders'
        }
    });


    useQuery(ordersStatesCountersQuery, {
        variables: {
            // @ts-ignore
            querySearch: getQuerySearch(offerIds)
        },
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        pollInterval: config.ORDER_COUNTER_POLLING_INTERVAL,
        onCompleted: (data) => {
            onCountersQueryCompleted(
              // @ts-ignore
            data, counters, setCounters, refetch, currentTab, setRefetchOrders, offerDate, offers, setIsOrdersLoading, orders,
            shouldFetchNewOfferOrders, fetchNewOfferOrders, offerIds
        ) },
        skip: !offerIds
    });

    if (!offerIds || !data) {
      // @ts-ignore
        return <Component orderCount={getOrderCount(counters)} orders={orders} tabsCounters={counters} isLoading={isOrdersLoading}/>;
    }

    return <PageContent loading={loading} error={error} data={data} networkStatus={networkStatus}
                        tabsCounters={counters}
    />;
};

export default Home;

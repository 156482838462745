import React, { useRef, useContext } from 'react';
import IdleTimer from 'react-idle-timer';

import config from 'config.json';
import Modal from 'components/Modal';
import AppContext from 'AppContext';
import { TabKey } from 'types/tab';
import PINModalContent from './PINModalContent';
import withRouter from 'utils/withRouter';

const PINModalTrigger = ({ router }) => {
    const idleTimer = useRef<IdleTimer>(null);
    const {
        searchDisplay,
        onSearch,
        idleTime: { isModalOpen, setModalOpen, setExpired },
        home: { setCurrentTab },
    } = useContext(AppContext.Context);

    const toggleModal = (isOpen?: boolean) => {
        const updatedIsModalOpen = isOpen === undefined ? !isModalOpen : isOpen;
        const idle = idleTimer.current;

        if (idle && updatedIsModalOpen) {
            idle.pause();
        } else if (idle) {
            idle.reset();
            setExpired(false);
        }
        setModalOpen(updatedIsModalOpen);
    };

    const onIdleTimeExpiration = () => {
        // reset search
        if (searchDisplay) {
            onSearch('');
        }
        setExpired(true);
        setCurrentTab(TabKey.PREPARE);
        return router.navigate('/');
    };


    return (
      //@ts-ignore
        <IdleTimer ref={idleTimer} onIdle={onIdleTimeExpiration} timeout={config.MAX_IDLE_TIME}>
            <Modal isBlocking isOpen={isModalOpen}>
                <PINModalContent toggleModal={toggleModal} />
            </Modal>
        </IdleTimer>
    );
};

export default withRouter(PINModalTrigger);

import React from 'react';
import styled from 'styled-components';

import { ReactComponent as OrdersIcon } from 'ressources/img/orders.svg';

const Wrapper = styled.span`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.typography.fontSizeXXL}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

const Count = styled.span`
    margin-left: ${({ theme }) => theme.spacing.margin}px;
`;

interface IProps {
    count: number;
    className?: string;
}

const OrderCount = ({ count, className }: IProps) => (
    <Wrapper className={className}>
        <OrdersIcon />
        <Count>{count}</Count>
    </Wrapper>
);

export default OrderCount;

import React, { Component } from 'react';
import styled from 'styled-components';

import { getDisplayTime } from 'services/dateService';
import { ReactComponent as TimeIcon } from 'ressources/img/time.svg';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.typography.fontSizeXXL}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

const Time = styled.span`
    margin-left: ${({ theme }) => theme.spacing.margin}px;
`;

const initialState = { time: getDisplayTime() };
type IState = Readonly<typeof initialState>;

class Clock extends Component<{}, IState> {
    private intervalId: any;
    state = initialState;

    componentDidMount() {
        this.intervalId = setInterval(() => this.tick(), 15000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    tick() {
        this.setState({
            time: getDisplayTime(),
        });
    }

    render() {
        const { time } = this.state;
        return (
            <Wrapper>
                <TimeIcon />
                <Time>{time}</Time>
            </Wrapper>
        );
    }
}

export default Clock;

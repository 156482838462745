import React from 'react';
import { IOffer } from 'types/order';
import OfferSelectorComponent from './OfferSelectorComponent';

interface IProps {
    className?: string;
    formattedOffers: IOffer[];
    currentOffers: IOffer[] | undefined;
    handleChange: (offers: IOffer[]) => void;
}

const OfferSelector = ({ className, formattedOffers, currentOffers, handleChange }: IProps) => {

    return formattedOffers.length > 1 ? (
        <OfferSelectorComponent
            className={className}
            selectedOffers={currentOffers}
            options={formattedOffers}
            handleChange={handleChange}
        />
    ) : null;
};

export default OfferSelector;

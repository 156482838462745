import React from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ReactComponent as RemoveImg } from 'ressources/img/remove.svg';

const Wrapper = styled.div`
    padding-left: 30px;
`;

const ErrorCode = styled.div`
    font-size: 16px;
    margin-bottom: 8px;
`;
const Message = styled.div`
    font-size: 25px;
    line-height: 34px;
    font-style: italic;
    font-weight: 400;
`;

interface IToastProps {
    errorCode?: number;
    errorMessage?: string;
    type?: ErrorType;
}

export enum ErrorType {
    NETWORK = 'NETWORK',
    SEARCH = 'SEARCH',
    PRINTER = 'PRINTER',
    UPDATE = 'UPDATE',
}

const ToastComponent = ({ errorCode, errorMessage, type = ErrorType.NETWORK, t }: IToastProps & WithTranslation) => (
    <Wrapper>
        {errorCode && <ErrorCode>{t('component:toast.message.errorCode', { errorCode })}</ErrorCode>}
        <Message>{errorMessage || t(`component:toast.message.${type}`)}</Message>
    </Wrapper>
);

export const Toast = withTranslation()(ToastComponent);

const StyledButton = styled.button`
    background: none;
    border: 0px solid transparent;
    &:focus {
        outline: none;
    }
`;

export const CloseButton = ({ closeToast }: any) => (
    <StyledButton onClick={closeToast}>
        <RemoveImg />
    </StyledButton>
);

import { graphql } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';

import { IOrder, OrderState } from 'types/order';
import { changeOrderState, changeOrderStateVariables } from 'types/graphql/changeOrderState';

const changeOrderStateMutation = loader('../queries/changeOrderState.graphql');

export const withStateMutation = graphql<any, changeOrderState, changeOrderStateVariables, {}>(
    changeOrderStateMutation,
    {
        props: props => {
            return {
                updateOrderState: props.mutate,
            };
        },
    }
);

export const stateMutationOptimisticResponse = (order: IOrder, nextState: OrderState) => {
    return {
        changeOrderState: {
            __typename: 'Order',
            id: order.id,
            state: nextState,
            updated: new Date().toISOString(),
        },
    };
};

import get from 'lodash/get';
import { OfferTemplateWithdrawalType } from 'types/graphql/globalTypes';

import { listOffers_offers, listOffers_offers_edges_node_OfferTemplate } from 'types/graphql/listOffers';
import { IOffer } from 'types/order';

// CAT offerTemplates have been removed
export function getAvailableOffers(offers: listOffers_offers): IOffer[] {
  // @ts-ignore
    return (get(offers, 'edges') || [])
        .map((edge) => edge.node as listOffers_offers_edges_node_OfferTemplate)
        .filter(
            (template) =>
                template.offers &&
                template.offers.length > 0 &&
                template.withdrawalType !== OfferTemplateWithdrawalType.TABLE_SERVICE
        )
        .map((template) => {
            return {
                id: template.offers[0].id,
                value: template.offers[0].id,
                label: template.name,
                secondaryLabel: `${new Date(template.offers[0].withdrawRange.split('/')[0]).toLocaleDateString()}`,
                offerTemplateId: template.id,
                withdrawalType: template.withdrawalType,
                isInAdvance: template.daysInAdvanceEnd > 0,
                withdrawRange: template.offers[0].withdrawRange,
                offerTemplateOffers: template.offers,
                pos: template.pos
            };
        });
}

export function getAvailableOffersWithOrders(currentOffers: IOffer[]) {
    return Object.entries(currentOffers.reduce((avaibleOffersWithOrders: any, currentOffer: IOffer) => {
        if(!currentOffer.offerTemplateOffers) return avaibleOffersWithOrders;
        currentOffer.offerTemplateOffers.forEach((offer) => {
            const label = new Date(offer.withdrawRange.split('/')[0]).toLocaleDateString();
            avaibleOffersWithOrders[label] = avaibleOffersWithOrders[label] ?  [...avaibleOffersWithOrders[label], offer.id]: [offer.id]
        })
        return avaibleOffersWithOrders;
    }, {})).map(([key, value]) => ({ label: key, value })).sort((a, b) => {
        return (
            new Date(a.label).valueOf() -
            new Date(b.label).valueOf()
        );
    });
}

import React, { useContext } from 'react';
import styled from 'styled-components';

import { IOrder } from 'types/order';
import { getFormattedOrders, getOrderCount } from 'services/orderService';
import { Loader } from 'components';
import Header from './components/Header';
import Content from './components/Content';
import AppContext from 'AppContext';
import { ordersStatesCounters_ordersStatesCounters } from '../../types/graphql/ordersStatesCounters';

const StyledLoader = styled(Loader)`
    height: calc(100vh - ${({ theme }) => theme.height.header}px);
`;

interface IComponentProps {
    orders: IOrder[];
    isLoading?: boolean;
    orderCount: number;
    tabsCounters: ordersStatesCounters_ordersStatesCounters
}

export const Component = ({ orders, isLoading, orderCount, tabsCounters }: IComponentProps) => (
    <>
        <Header orderCount={orderCount} />
        {isLoading ? <StyledLoader /> : <Content orders={orders} tabsCounters={tabsCounters} />}
    </>
);

const PageContent = ({ data, loading, error, tabsCounters }: any) => {
    const { cancellableUpdatingOrderIds, updatingOrderIds, searchDisplay  } = useContext(AppContext.Context);
    if (error || !data) return null;
    const { searchedOrders } = data;
    const currentOrders = searchDisplay ? searchedOrders : [];
    const formattedOrders = getFormattedOrders(currentOrders || [], cancellableUpdatingOrderIds, updatingOrderIds);

    return (
        <Component
            isLoading={loading}
            orders={formattedOrders}
            orderCount={getOrderCount(tabsCounters)}
            tabsCounters={tabsCounters}
        />
    );
};

export default PageContent;

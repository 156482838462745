import React, { useContext } from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import noResultBackgroundImg from 'ressources/img/no-result.png';
import noOrderBackgroundImg from 'ressources/img/no-order.png';
import { TabKey } from 'types/tab';
import AppContext from 'AppContext';

interface WrapperProps {
    readonly searchDisplay: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    height: 100%;
    padding-top: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${({ searchDisplay }) => (searchDisplay ? noResultBackgroundImg : noOrderBackgroundImg)}) no-repeat;
    background-size: ${({ searchDisplay }) => (searchDisplay ? 700 : 1300)}px;
    background-position: 0px ${({ searchDisplay }) => (searchDisplay ? 200 : 0)}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

const Title = styled.span`
    margin-bottom: ${({ theme }) => theme.spacing.marginL}px;
    font-size: ${({ theme }) => theme.typography.fontSizeXXL}px;
`;
const SubTitle = styled.span`
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
`;

export enum OrderListKey {
    SEARCH = 'SEARCH',
}

interface IProps {
    type: TabKey | OrderListKey.SEARCH;
}

function EmptyList({ type, t }: IProps & WithTranslation) {
    const { searchDisplay } = useContext(AppContext.Context);
    return (
        <Wrapper searchDisplay={searchDisplay}>
            <Title>{t(`page:home.emptyList.${type}.title`)}</Title>
            <SubTitle>{t(`page:home.emptyList.${type}.subTitle`)}</SubTitle>
        </Wrapper>
    );
}

export default withTranslation()(EmptyList);

import React, { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import config from 'config.json';
import AppContext from 'AppContext';

import { getAvailableOffers, getAvailableOffersWithOrders } from 'services/offerService';
import { getTodaysStartQuerySearch } from 'services/queryService';
import { LocalStorageKey, getItem } from 'services/localStorageService';
import { listOffers } from 'types/graphql/listOffers';
import isEqual from "lodash/isEqual";

import { Toast } from 'components';
import Clock from './Clock';
import OrderCount from './OrderCount';
import SearchInput from './SearchInput';
import Logout from './Logout';
import OfferSelector from './OfferSelector';
import PrinterSelector from './PrinterSelector';
import DayOfferSelector from './DateOfferSelector';
import { IOffer } from 'types/order';
import isEmpty from "lodash.isempty";

const listOffersQuery = loader('../../../../queries/listOffers.graphql');
const getNetworkStatus = loader('../../../../queries/getNetworkStatus.graphql');

interface WrapperProps {
    readonly isDisconnected: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    position: relative;
    height: ${({ theme }) => theme.height.header}px;
    padding: ${({ theme }) => theme.spacing.paddingL}px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, isDisconnected }) =>
        isDisconnected ? theme.palette.background.errorOpacity : theme.palette.background.defaultOpacity};
`;

const Essentials = styled.span`
    position: absolute;
    left: ${({ theme }) => theme.spacing.paddingL}px;
    display: flex;
    align-items: center;
`;

const StyledOrderCount = styled(OrderCount)`
    margin: ${({ theme }) => theme.spacing.margin * 3}px;
`;

const RightSide = styled.div`
    position: absolute;
    right: ${({ theme }) => theme.spacing.paddingL}px;
    display: flex;
    align-items: center;
`;

const StyledLogout = styled(Logout)``;

const StyledPrinterSelector = styled(PrinterSelector)`
    margin-right: ${({ theme }) => theme.spacing.marginL}px;
    margin-top: 8px;
`;

const StyledOfferSelector = styled(OfferSelector)`
    margin-right: ${({ theme }) => theme.spacing.marginL}px;
    z-index: ${({ theme }) => theme.zIndex.modalOverlay};
`;

const StyledDayOfferSelector = styled(DayOfferSelector)`
    margin-right: ${({ theme }) => theme.spacing.marginL}px;
    z-index: ${({ theme }) => theme.zIndex.modalOverlay};
`;

type IProps = {
    orderCount: number;
};

const Header = ({ orderCount }: IProps) => {
    const { t } = useTranslation();
    const { setOffers, offers: currentOffers, setOfferDate, cleanOrdersAndCounters, shouldFetchNewOfferOrders, offerDate } = useContext(AppContext.Context);

    const onCompleted = (data: listOffers) => {
        const formattedOffers = data && data.offers ? getAvailableOffers(data.offers) : [];
        if (formattedOffers.length === 0) {
            toast(<Toast errorMessage={t('app:error.no_offer') as string} />);
            setOffers(undefined);
            return;
        }
        if(currentOffers) return;
        const cachedOffersIds = getItem<string>(LocalStorageKey.OFFERS_IDS);
        if(!isEmpty(cachedOffersIds)) return setOffers(formattedOffers.filter(offer => cachedOffersIds.includes(offer.id)))
        return setOffers(!isEmpty(formattedOffers) ? [formattedOffers[0]] : []);
    };

    // Todo - restore support to isOffline query
    const getNetworkStatusData = useQuery(getNetworkStatus);
    const isOffline = getNetworkStatusData.data && getNetworkStatusData.data.isOffline;

    const { data, loading = false } = useQuery(listOffersQuery,{
        // only trigger this query when get network status query is executed and returns
        //skip: isOffline === undefined,
        variables: {
            withdrawRangeQuerySearch: getTodaysStartQuerySearch('withdrawRange'),
            first: 700
        },
        errorPolicy: 'all',
        pollInterval: config.OFFER_POLLING_INTERVAL,
        onCompleted,
        notifyOnNetworkStatusChange: true
    });

    const formattedOffers = data != null ? getAvailableOffers(data.offers) : [];
    const offersWithOrders = useMemo(() => currentOffers != null
        ? getAvailableOffersWithOrders(currentOffers)
        : [], [currentOffers]);

    const handleChange = (offers: IOffer[]) => {
        setOfferDate(undefined);
        setOffers(offers);
        if(offers && !isEqual(offers.map(({ id }) => id), offerDate)){
            cleanOrdersAndCounters();
            shouldFetchNewOfferOrders(true);
        }
    };

    // replace query with useQuery
    return (
        <Wrapper isDisconnected={isOffline}>
            <Essentials>
                <Clock />
                <StyledOrderCount count={orderCount} />
                {formattedOffers.length > 1 ? (
                    <StyledOfferSelector
                        formattedOffers={formattedOffers}
                        currentOffers={currentOffers}
                        handleChange={handleChange}
                    />
                ) : null}
                {!loading ? (
                    <StyledDayOfferSelector offersWithOrders={offersWithOrders} />
                ) : null}
                <SearchInput />
            </Essentials>
            <RightSide>
                <StyledPrinterSelector />
                <StyledLogout />
            </RightSide>
        </Wrapper>
    );
};

export default Header;

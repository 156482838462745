import React from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import { useTransition, animated } from '@react-spring/web';

import { ReactComponent as LockImg } from 'ressources/img/locked.svg';

import Portal from './Portal';

const ModalWrapper = styled.section`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    overflow-y: auto;
    z-index: ${({ theme }) => theme.zIndex.modalOverlay};
    &.blocking {
        z-index: ${({ theme }) => theme.zIndex.blockingModalOverlay};
    }
`;

const ModalCard = animated(styled.div`
    height: 100vh;
    width: ${({ theme }) => theme.width.sideModal}px;
    color: ${({ theme }) => theme.palette.text.medium};
    display: flex;
    flex-direction: column;
    background-color: white;
`);

const Background = animated(styled.div`
    position: relative;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.palette.common.black};
`);

const StyledLockImg = styled(LockImg)`
    position: fixed;
    top: 50vh;
    left: calc((100vw - ${({ theme }) => theme.width.sideModal}px) / 2);
`;

interface IProps {
    isOpen: boolean;
    isBlocking?: boolean;
    closeModal?: any;
    theme: DefaultTheme;
    children: any;
}

const Modal = (props: IProps) => {
    const { children, isOpen, isBlocking, theme, closeModal } = props;
    const pointerEvents = isOpen ? 'all' : 'none';

    const onModalToggle = () => {
        if (!isBlocking && closeModal) closeModal();
    };

    const transitions = useTransition(isOpen, {
        config: { duration: 250 },
        from: { width: 0, opacity: 0 },
        enter: { width: theme.width.sideModal, opacity: 1 },
        leave: { width: 0, opacity: 0 },
    })

    return transitions((style, isOpen) => (
        <Portal>
            {isOpen && (
                <ModalWrapper className={isBlocking ? 'blocking' : ''}>
                    <Background
                        style={{
                            pointerEvents,
                            backgroundColor: theme.palette.background.darkOpacity,
                            opacity: style.opacity,
                            zIndex: 9998
                        }}
                        onTouchStart={onModalToggle}
                    >
                        {isBlocking && <StyledLockImg />}
                    </Background>
                    <ModalCard
                        style={{
                            width: style.width,
                            zIndex: 9999
                        }}
                    >
                        {children}
                    </ModalCard>
                </ModalWrapper>
            )}
        </Portal>
    ))
}

export default withTheme(Modal);

import { Component } from 'react';
import { createPortal } from 'react-dom';

const portalRoot = document.getElementById('portal');

interface IPortalProps {
    children?: any;
}
  
interface IPortalState {
}

class Portal extends Component<IPortalProps, IPortalState> {
    private el = document.createElement('div');

    componentDidMount = () => {
        portalRoot && portalRoot.appendChild(this.el);
    };

    componentWillUnmount = () => {
        portalRoot && portalRoot.removeChild(this.el);
    };

    render() {
        const { children } = this.props;
        return createPortal(children, this.el);
    }
}

export default Portal;

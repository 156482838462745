import React, { MouseEvent, useContext } from 'react';
import styled from 'styled-components';
import { OrderState, IOrder } from 'types/order';
import { actionIcons, moveOrderToLocalOrderList, revertMovingOrderToLocalOrderList } from 'services/orderService';
import { withStateMutation, stateMutationOptimisticResponse } from 'queries/mutations';
import AppContext from 'AppContext';
import printService from 'services/printService';
import { toast } from 'react-toastify';
import { Toast } from 'components';
import { ErrorType } from 'components/Toast';
import cloneDeep from 'lodash/cloneDeep';

interface ButtonProps {
    readonly newState: OrderState;
    readonly backgroundColor?: string;
}

export const Button = styled.button<ButtonProps>`
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border-width: 0;
    outline: none;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.28);
    background-color: ${({ theme, newState, backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.palette.background.stateUpdate[newState]};
    transition: transform 300ms;
    z-index: ${({ theme }) => theme.zIndex.actionButton};
    &:active {
        transform: scale(1.2, 1.2);
    }
`;

// @ts-ignore
export const PrintButton = styled(Button)`
    background: ${({ theme }) => theme.palette.background.lightDark};
`;

interface IProps {
    updateOrderState: Function;
    order: IOrder;
    className?: string;
    immediate?: boolean;
    backgroundColor?: string;
    newState: OrderState;
    onCancelled?: Function;
}

const usePrinter = (order: IOrder): Function => {
    const {
        printer: { selected, setModalOpen, isModalOpen }
    } = useContext(AppContext.Context);

    if (!selected && !isModalOpen) {
        return setModalOpen(true);
    }
    return async () => {
        try {
            await printService.print(order);
        } catch (error) {
            toast(<Toast type={ErrorType.PRINTER} />);
        }
    };
};

function Action({
    order,
    order: { id, paymentMethod },
    className,
    updateOrderState,
    immediate,
    newState,
    backgroundColor,
    onCancelled,
}: IProps) {
    const {
        onUpdateStart,
        orders: ordersList,
        home: { currentTab },
        setOrder,
        setOrders,
        setCountersAtTab,
        counters,
    } = useContext(AppContext.Context);
    const printOrder = usePrinter(order);

    const onClick = async (e: MouseEvent<HTMLButtonElement>) => {
        const previousOrderState = { ...order };
        const optimisticOrderUpdated = {
            ...order,
            state: newState,
            updated: new Date(), //.toISOString()
        };
        const ordersListCopy = cloneDeep(ordersList);

        e.stopPropagation();
        if (!newState) return;
        if (newState === OrderState.REFUSED && onCancelled) {
            return onCancelled(newState);
        } else if (newState === OrderState.COMPLETED && !immediate) {
            onUpdateStart(id);
        } else if (newState === OrderState.READY && !immediate) {
            onUpdateStart(id);
        }
        try {
            // to give faster feedback, assume the order will be updated with success and try to move it to the right tab right away
            moveOrderToLocalOrderList(
                currentTab,
                optimisticOrderUpdated,
                setOrder,
                ordersList,
                setOrders,
                setCountersAtTab,
                counters
            );

            await updateOrderState({
                variables: { idOrder: id, newState, paymentMethod },
                optimisticResponse: stateMutationOptimisticResponse(order, newState),
            });

            if (newState === OrderState.PREPARING) {
                await printOrder(order);
            }
        } catch (error) {
            console.log(error);
            // on error, revert the optimistically order update performed
            revertMovingOrderToLocalOrderList(
                optimisticOrderUpdated,
                previousOrderState,
                ordersListCopy,
                setOrders,
                setCountersAtTab,
                counters
            );
        }
    };
    return (
        <Button
            type="button"
            onClick={onClick}
            className={className}
            backgroundColor={backgroundColor}
            newState={newState}
        >
            {actionIcons[newState]}
        </Button>
    );
}

interface IPrintActionProps {
    order: IOrder;
}

export const PrintAction = ({ order }: IPrintActionProps) => {
    const printOrder = usePrinter(order);
    return (
        <PrintButton onClick={async () => await printOrder(order)} newState={OrderState.PREPARING}>
            {actionIcons[OrderState.PREPARING]}
        </PrintButton>
    );
};

export default withStateMutation(Action);

import styled from 'styled-components';

interface IButtonProps {
    color?: 'primary' | 'secondary' | 'confirm' | 'error';
}
export const Button = styled.button<IButtonProps>`
    padding: ${({ theme }) => theme.spacing.padding}px;
    display: flex;
    flex-grow: 0;
    min-width: 140px;
    min-height: 60px;
    border-radius: 27px;
    background-color: ${({ color = 'primary', theme }) => theme.palette.button[color]};
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 20px;
`;

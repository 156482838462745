import React, { ChangeEvent, FormEvent, useContext, useRef } from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ReactComponent as RemoveIcon } from 'ressources/img/remove.svg';
import { ReactComponent as LoopIcon } from 'ressources/img/loop.svg';
import AppContext from 'AppContext';

const InputWrapper = styled.form`
    position: relative;
`;

const padding = 20;
const loopIconSize = 30;
const searchIconMargin = 4;

const Input = styled.input`
    height: ${({ theme }) => theme.height.searchInput}px;
    width: ${({ theme }) => theme.width.searchInput}px;
    padding: ${padding}px ${loopIconSize + 2 * padding}px;
    border: none;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.palette.background.lightOpacity};
    color: ${({ theme }) => theme.palette.text.light};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    .active & {
        background-color: ${({ theme }) => theme.palette.common.white};
        color: ${({ theme }) => theme.palette.text.extraDark};
    }
    &::placeholder {
        font-size: ${({ theme }) => theme.typography.fontSizeL}px;
        color: ${({ theme }) => theme.palette.text.light};
    }
    &:active,
    &:focus {
        outline: none;
        background-color: ${({ theme }) => theme.palette.common.white};
        color: ${({ theme }) => theme.palette.text.extraDark};
        &::placeholder {
            color: ${({ theme }) => theme.palette.text.extraDark};
        }
    }
`;

const StyledLoopIcon = styled(LoopIcon)`
    position: absolute;
    left: ${padding}px;
    top: ${({ theme }) => (theme.height.searchInput - loopIconSize) / 2}px;
    height: ${loopIconSize}px;
    width: ${loopIconSize}px;
    path {
        fill: ${({ theme }) => theme.palette.common.white};
    }
    .active & {
        path {
            fill: ${({ theme }) => theme.palette.common.blue};
        }
    }
    input:active + &,
    input:focus + & {
        path {
            fill: ${({ theme }) => theme.palette.common.blue};
        }
    }
`;
const StyledRemoveIcon = styled(RemoveIcon)`
    position: absolute;
    top: ${searchIconMargin}px;
    right: ${searchIconMargin}px;
    height: ${({ theme }) => theme.height.searchInput - 2 * searchIconMargin}px;
    width: ${({ theme }) => theme.height.searchInput - 2 * searchIconMargin}px;
`;

const SearchInput = ({ t }: WithTranslation) => {
    const { searchValue, onSearch } = useContext(AppContext.Context);
    const inputEl = useRef(null);
    function onChange(event: ChangeEvent<HTMLInputElement>): void {
        onSearch(event.target.value);
    }
    function onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        if (inputEl !== null && inputEl.current !== null) {
            const currentInputEl = (inputEl!.current as unknown) as HTMLInputElement;
            currentInputEl.blur();
        }
    }
    return (
        <InputWrapper onSubmit={onSubmit} className={searchValue.length > 0 ? 'active' : ''}>
            <Input
                ref={inputEl}
                id="search"
                type="text"
                placeholder={t('page:home.header.searchInput.placeholder') as string}
                onChange={onChange}
                value={searchValue}
                autoComplete="off"
            />
            <StyledLoopIcon />
            {searchValue.length > 0 && <StyledRemoveIcon onClick={() => onSearch('')} />}
        </InputWrapper>
    );
};

export default withTranslation()(SearchInput);
